<app-case-breadcrumb [caseId]="caseService.case._id"></app-case-breadcrumb>

<div  class="subject-header">
  <h1>
    <span [innerHTML]="templateTitle|unsanitizeHtml"></span>
  </h1>
  <div secondary>
    <span [innerHTML]="templateDescription|unsanitizeHtml" class="description"></span>
    <div class="confirm-correct-case-prompt" *ngIf="caseService.openCaseConfirmed === false">
      <paper-button class="button" (click)="onOpenCaseConfirmButtonClick()">Confirm</paper-button>
    </div>
  </div>
</div>

<div class="wrapper">
  <div class="cover-when-not-confirmed" *ngIf="caseService.openCaseConfirmed === false"></div>
  <div *ngFor="let eventInfo of caseEventsInfo">
    <app-case-event-list-item *ngFor="let caseEvent of eventInfo.caseEvents" [caseEvent]="caseEvent"
      [caseDefinition]="caseService.caseDefinition" [caseEventDefinition]="eventInfo.caseEventDefinition"
      [case]="caseService.case" routerLink="/case/event/{{caseService.case._id}}/{{caseEvent.id}}">
    </app-case-event-list-item>
  </div>
  <div class="icon-list-item event new-event">
    <mwc-icon slot="item-icon">event</mwc-icon>
    <div>
    <div>{{'New Event'|translate}}</div>
      <div >
          <form (ngSubmit)="onSubmit()" #form="ngForm">
            <div class="dropdown-container">
              <select name="selected-event-type" [(ngModel)]="selectedNewEventType">
                <option value="" disabled selected>{{'Choose type...'|translate}}</option>
                <option *ngFor="let eventInfo of creatableCaseEventsInfo" [value]="eventInfo.caseEventDefinition.id">
                  {{eventInfo.caseEventDefinition.name}}</option>
              </select>
            </div>         
            <div class="text-right">
              <paper-button (click)="onSubmit()" class="button">{{'Create'|translate}}</paper-button>
            </div>
          </form>
        </div>
    </div>
    </div>
</div>

<div *ngFor="let conflict of conflicts" class="icon-list-item search-result" routerLink="/groups/{{groupId}}/data/issues/{{conflict._id}}">
  <mwc-icon slot="item-icon">receipt</mwc-icon>
  <div>
    <div><strong>{{conflict.label}}</strong> {{moment(conflict.tangerineModifiedOn).format('YYYY-MM-DD hh:mm a')}}
    </div>
    <div secondary>
      Status: {{conflict.status}}
      <span *ngIf="conflict.docType === 'case' && conflict.events.length > 0 && conflict.events[0].data.conflict">Merged: {{conflict.events[0].data.conflict.merged}}</span>
      <span class="secondarySpacer" *ngIf="conflict.formResponseId">Response id: {{conflict.formResponseId | slice:0:8}}</span>
      <span class="secondarySpacer" *ngIf="conflict.caseId">Case id: {{conflict.caseId | slice:0:8}}</span>
      Device: {{conflict.tangerineModifiedByDeviceId | slice:0:8}}
    </div>
  </div>
</div>
